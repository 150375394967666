import React, { Component } from 'react';
import { MDBBtn, MDBAlert } from 'mdb-react-ui-kit';
import Select from 'react-select';
import { Formik, Field, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const loginValidationSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  password: Yup.string().required('Password is required'),
  clinic: Yup.number().typeError('Please select a clinic').required('Clinic is required'),
});

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(values, { setStatus }) {
    this.props.onLogin(values, setStatus);
  }

  render() {
    return (
      <Formik
        initialValues={{
          username: this.props.account?.username ?? '',
          password: this.props.account?.password ?? '',
          clinic: this.props.selectedClinic ?? '',
        }}
        onSubmit={this.handleSubmit}
        validationSchema={loginValidationSchema}
      >
        {({ status, errors, touched, setFieldTouched, submitCount }) => (
          <Form>
            {status && (
              <MDBAlert open={status} color="danger" style={{
                maxWidth: '500px',
              }}>
                {status.api_error_message}
                <Link to={`${process.env.PUBLIC_URL}/admin`} hidden={this.props.dataTypesExist}>
                  SIHA Admin
                </Link>
              </MDBAlert>
            )}
            {!status && submitCount > 1 && (
              <MDBAlert open={!status && submitCount > 1} color="danger" style={{
                maxWidth: '500px',
              }} dismiss>
                Something went wrong. Please try again.
              </MDBAlert>
            )}

            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="username" style={{ display: 'block' }}>
                Username <sup>*</sup>
              </label>
              <Field type="username" name="username" className="form-control" />
              <ErrorMessage name="username" className="error" component="div" />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="password" style={{ display: 'block' }}>
                Password <sup>*</sup>
              </label>
              <Field name="password" type="password" className="form-control" />
              <ErrorMessage name="password" className="error" component="div" />
            </div>
            <div style={{ marginBottom: '10px' }}>
              <label htmlFor="clinic" style={{ display: 'block' }}>
                Clinic <sup>*</sup>
              </label>
              <Field
                name="clinic"
                component={({ field, form }) => (
                  <React.Fragment>
                    <Select
                      className="ml-auto"
                      id="clinic-select"
                      options={this.props.clinicNames}
                      value={
                        this.props.clinicNames
                          ? this.props.clinicNames.find(option => option.value === field.value)
                          : ''
                      }
                      placeholder="Select a clinic.."
                      onChange={option => {
                        form.setFieldValue(field.name, option.value);
                        this.props.onClinicSelectChange(option);
                      }}
                      onBlur={() => setFieldTouched('clinic', true)}
                    />
                    {touched.clinic && errors.clinic && (
                      <span className="error">{errors.clinic}</span>
                    )}
                  </React.Fragment>
                )}
              />
            </div>

            {Object.keys(errors).length === 0 ? (
              <MDBBtn
                gradient="blue"
                type="submit"
                className="btn-block z-depth-2"
                style={{ marginTop: '20px', marginLeft: '0px' }}
              >
                Log in
              </MDBBtn>
            ) : (
              <MDBBtn
                gradient="blue"
                type="submit"
                className="btn-block z-depth-2"
                style={{ marginTop: '20px', marginLeft: '0px' }}
                disabled
              >
                Log in
              </MDBBtn>
            )}
          </Form>
        )}
      </Formik>
    );
  }
}

LoginForm.propTypes = {
  clinicNames: PropTypes.array,
  selectedClinic: PropTypes.object,
  account: PropTypes.object,
  onLogin: PropTypes.func,
  onClinicSelectChange: PropTypes.func,
  dataTypesExist: PropTypes.bool,
};

export default LoginForm;
