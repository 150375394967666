import React, { Component } from 'react';
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBContainer,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from 'mdb-react-ui-kit';
import { FaChartBar, FaUserInjured, FaUserFriends, FaUser, FaMobile } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from '../assets/images/siha-white.svg';

class Navbar extends Component {
  render() {
    const styleLoginMenuItems = !this.props.isLoggedIn ? {} : { display: 'none' };
    const styleLoggedInMenuItems = this.props.isLoggedIn ? {} : { display: 'none' };
    return (
      <div>
        <MDBNavbar className="bg-blue m-b10" light expand="md">
          <MDBContainer fluid className="navbar-container">

            <div className="navbar-brand-container">

            <MDBNavbarBrand href={`${process.env.PUBLIC_URL}`}>
                <img
                    src={Logo}
                  style={{ height: '40px' }}
                />
            </MDBNavbarBrand>
            </div>
            <div className="navbar-link-container ">

              <MDBNavbarNav className='navbar-links' style={{ ...styleLoggedInMenuItems }}>
              <MDBNavbarItem>
                <NavLink to={`${process.env.PUBLIC_URL}/overview`} className="navbar-link">
                  <FaChartBar className="" />
                  Clinic Overview
                </NavLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <NavLink to={`${process.env.PUBLIC_URL}/patients/all`} className="navbar-link">
                    <FaUserInjured className="" /> Patients
                </NavLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <NavLink to={`${process.env.PUBLIC_URL}/devices/all`} className="navbar-link">
                    <FaMobile className="" /> Devices
                </NavLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <NavLink to={`${process.env.PUBLIC_URL}/groups/all`} className="navbar-link">
                    <FaUserFriends className="" /> Groups
                </NavLink>
              </MDBNavbarItem>
                <MDBNavbarItem>
                  <NavLink to={`${process.env.PUBLIC_URL}/groupecg/`} className="navbar-link">
                    <FaUserFriends className="" /> Group ECG
                  </NavLink>
                </MDBNavbarItem>
            </MDBNavbarNav>
            </div>

            <div className="navbar-dropdown-container">
              <MDBNavbarToggler onClick={this.props.onNavBarDropdownClick} />

              <MDBCollapse className="me-auto" open={this.props.topNavDropdownCollapse} navbar >
                <MDBNavbarNav >
                <MDBNavbarItem style={styleLoginMenuItems}>
                  <MDBNavbarLink active href={`${process.env.PUBLIC_URL}/login`} className="login-nav-link">
                    Login
                  </MDBNavbarLink>
                </MDBNavbarItem>
                <MDBNavbarItem style={styleLoggedInMenuItems}>
                  <MDBDropdown>
                    <MDBDropdownToggle className="white-text">
                        <div className="d-md-inline d-none">
                        {' '}
                        <FaUser /> {this.props.username}
                      </div>
                    </MDBDropdownToggle>
                      <MDBDropdownMenu >
                        <NavLink to={`${process.env.PUBLIC_URL}/my-account`} >
                          <MDBDropdownItem className='navbar-dropdown-item'>
                            Account
                          </MDBDropdownItem>
                        </NavLink>
                        <NavLink to={`${process.env.PUBLIC_URL}/`} onClick={this.props.onLogoutClick}>
                          <MDBDropdownItem className='navbar-dropdown-item'>
                            Logout
                          </MDBDropdownItem>
                        </NavLink>

                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavbarItem>
              </MDBNavbarNav>
            </MDBCollapse>
            </div>

          </MDBContainer>
        </MDBNavbar>
      </div>
    );
  }
}

Navbar.propTypes = {
  isLoggedIn: PropTypes.bool,
  account: PropTypes.object,
  topNavDropdownCollapse: PropTypes.bool,
  onNavBarDropdownClick: PropTypes.func,
  onLogoutClick: PropTypes.func,
};

export default Navbar;
